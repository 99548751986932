<template>
  <router-view></router-view>
</template>

<script>
import { uiHelper, locale } from '@/util'
import { ROUTE_NAME } from '@/constants/route.constants'
export default {
  name: 'CasinoLayout',
  metaInfo: {
    title: `${locale.getMessage('meta.casino_title')}`,
    titleTemplate: `%s | ${uiHelper.getHost()}`,
    meta: [{ charset: 'utf-8' }, { name: 'description', content: `${locale.getMessage('meta.casino_description')}` }, { name: 'keyword', content: `${locale.getMessage('meta.casino_keyword')}` }]
  },
  methods: {
    openRegisterDialog() {
      this.$parent.openRegisterDialog()
    },
    openLoginDialog() {
      this.$parent.openLoginDialog()
    },
    goToRegisterPage() {
      this.$router.push({
        name: ROUTE_NAME.REGISTER,
        params: { lang: this.$route.params.lang }
      })
    },
    goToLoginPage() {
      this.$router.push({
        name: ROUTE_NAME.LOGIN,
        params: { lang: this.$route.params.lang }
      })
    }
  }
}
</script>

<style>
.game_icon_sizing {
  border-radius: 15px !important;
}
</style>
